import withRoot from "../../utils/withRoot";
import React from "react";
import SEO from "../../components/SEO";
import Page from "../../components/Page";
import Title from '../../components/Title'
import Para from '../../components/Para'
import TextListItem from '../../components/TextListItem'
import paulb from './Paul 2.jpg'
import Grid from '@material-ui/core/Grid'
import CtaButton from '../../components/CtaButton'
import {Link} from 'gatsby'


const About = props => {
    return (
        <Page title="How we work with you">
            <SEO title="How we work with you"/>
            <Para>
                How Ashridge Technologies can help you succeed with <Link to='/benefits'>Low Code development</Link>
            </Para>

            <Title level='h3'>You build - we support you</Title>
            <Para>
                We train you to use the Low Code tool, then work alongside you as you build your own software.<br/>
                You get the lowest cost, and full control to extend and change your system in the future, but we’ll be
                there to support you with help and advice whenever you need it.
            </Para>
            <Title level="h3">Your choice</Title>
            <Para>
                We offer a totally flexible service to suit you, not a fixed package to suit us.<br/>
                So you choose what you want us to do, when you want it and how much you want to spend.<br/>
                We can work with you every day, or do regular reviews, or just a one-off call to answer your questions.
            </Para>


            <Title>Our approach</Title>
            <Title level='h3'>Flexible consultancy</Title>
            <Para>
                We are here to help you get the best results, whatever that means for you.
                We don’t have a fixed approach, or try to force every project and every client into the same mould.
            </Para>
            <Para>
                You can have as much or as little help as you need - full-time, or an hour a month.
                You could get the project done as quickly as possible, or spread it over several months, learning from
                each stage.
                You can change how much we do for you as you go, or pause the project and resume it later (so long as your consultant is available, of course).
            </Para>


            <Title level='h3'>All-round professional approach</Title>
            <Para>
                We don’t just use Low Code to bash out software as quickly as possible. And we won’t encourage you to do
                that either.
            </Para>
            <Para>
                You still need to think about what you are going to do and make sure it works when you have
                done it. You should prioritise to make sure you do the important stuff first - and stop when you have
                done enough.
            </Para>
            <Para>
                We have been doing this for many years on conventional IT projects, and now we apply the
                same high standards to Low Code development.
            </Para>

            <Title level='h3' id='halc'>HALC - Highly Agile Low Code</Title>
            <Para>
                We have created our own method specifically to run Low Code projects, by adapting well-proven practices
                from conventional development.
                The emphasis is on speed, flexibility, and reducing overhead.
            </Para>

            <Para>
                Typical one or two-week development cycles are replaced by a simple daily routine.
                The main users and the developer work together directly, going from fact-finding, straight into design and then programming on each feature.
            </Para>

            <Para>Because of the close co-operation and reduced timescales, the timetable becomes just:</Para>
            <ul>
                <TextListItem>Start each day with a planning meeting</TextListItem>
                <TextListItem>Build features during the day</TextListItem>
                <TextListItem>Review at the end of the day</TextListItem>
                <TextListItem>Publish to the live system for you to use</TextListItem>
            </ul>
            <Para>Then repeat until your software does everything you need</Para>

            <Title level='h3'>
                Technical expertise
            </Title>
            <Para>
                We understand the nuts and bolts of IT as well.
                So if you need advice on any technical issue like domain names, security, data storage, email,
                connecting to other systems or just what some weird three-letter abbreviation means, we can help. Or if
                you need a specialist add-on for your system, like connecting to an accounting system or displaying live
                football results, we have the low-level programming expertise to build that for you.
            </Para>

            <Title level='h3'>
                Knowledge transfer
            </Title>
            <Para>
                Rather than build software for you, we enable you and your staff to do it yourselves.
            </Para>
            <Para>
                Through training and close
                collaboration on projects, we can leave you with a lasting asset on top of the system itself - the
                ability to change and extend it whenever you want, and build further systems without depending on anyone
                else - or paying a fortune for a small change.
            </Para>

            <Title level='h3'>
                Long term partnership
            </Title>
            <Para>
                After you have worked with us for a while, we hope you won’t need us any more - you will be up to speed and successfully building your own systems.
            </Para>
            <Para>
                But you can still book an occasional hour with us to
                discuss a particular problem, review what you have been doing, or consult us about a special
                technical requirement. Or have us back for a month to spread the load on a big new project. We can be
                there for whatever you need.
            </Para>

            <Title level='h3'>
                Charity rates
            </Title>
            <Para>
                We offer a limited number of days’ consultancy each year at considerably lower rates to registered
                charities. If you work for a charity, please contact us for details.
            </Para>
            <Title level='h3'>
                Remote working
            </Title>
            <Para>
                If possible, we will have a face-to-face meeting at the start of a project, but after that we work via
                online meetings as much as possible, to save both time and CO2 emissions. Modern tools like Zoom let you
                work on the same screen, talk and point to things - so it’s pretty much like working side by side.
            </Para>


            <Title>Our people</Title>

            <Title level='h3'>Paul Boocock</Title>
                <Grid container>
                    <Grid item><img src={paulb} alt="Paul Boocock" style={{height: 100, borderRadius: 50}}/></Grid>
                    <Grid item><Para style={{maxWidth: '30rem', marginLeft: '3rem'}}>
                        Paul runs Ashridge Technologies.  He has spent over 30 years developing software,
                        working in every part of the process from gathering user requirements to sorting out networks.
                        But he is now on a new mission: to make bespoke software available to everyone, not just those with coding skills or lots of money.
                    </Para></Grid>
                </Grid>


            <Para align='center'><CtaButton href='/contact'>Tell us about your problems</CtaButton></Para>
            <Para align='center'><CtaButton href='/benefits' variant='outlined'>Learn more about Low Code</CtaButton></Para>
        </Page>
    );
};

export default withRoot(About);
