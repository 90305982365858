import React from 'react';
import withStyles from '@material-ui/styles/withStyles';
import {Button} from '@material-ui/core'
import {Link} from 'gatsby'
import {useTheme} from '@material-ui/styles'

const styles = {
};

const CtaButton = ({ children, href, variant = 'contained' }) => {
    const theme = useTheme();
    const style = {
        minWidth: '20em',
        color: variant === 'contained' ? theme.palette.primary.contrastText : theme.palette.primary.main
    }
    return (
        <Link to={href}>
            <Button variant={variant} color='primary' style={style}>
                {children}
            </Button>
        </Link>
    );
}

export default withStyles(styles)(CtaButton);
