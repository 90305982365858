import React from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography'

const styles = {
};

const TextListItem = ({ children, classes, style = {} }) => (
    <li><Typography variant='body1' classes={classes} style={style}>{children}</Typography></li>
);

export default withStyles(styles)(TextListItem);
